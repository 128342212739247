import React from "react";

import Section from "../Section";

const Sections = ({ sections }) => {
  return (
    <div>
      {sections.map((item, i) => {
        return <Section key={i} index={i} {...item} />;
      })}
    </div>
  );
};

export default Sections;
