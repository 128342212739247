import React from "react";

import { zeroPad, isOdd } from "../../lib/mathUtils";

import Image from "./Image";
import LinkInternal from "./LinkInternal";

const Section = ({ index, label, image, heading, description, link }) => {
  const order = isOdd(index + 1) ? "order-1" : "order-2";
  return (
    <div
      className={`md:container md:px-6 xl:px-0 mx-6 md:mx-auto my-16 flex items-center ${
        order === "order-2" ? "flex-col-reverse" : "flex-col"
      } md:flex-row gap-12 lg:gap-32`}
    >
      <figure className={`w-80 h-80 lg:w-[500px] lg:h-[500px] octo ${order}`}>
        <Image image={image} className={`object-cover w-full h-full`} />
      </figure>
      <div className="flex flex-1 flex-col justify-center order-1">
        <div className="flex items-center text-sm text-cinnabar">
          {zeroPad(index + 1, 2)}
          <span className="inline-block mx-2 w-11 bg-cinnabar h-[1px]"></span>
          {label}
        </div>
        <h3 className="mt-4 font-bold text-everglade uppercase text-xl md:text-3xl tracking-wider">
          {heading}
        </h3>
        <div className="mt-6 md:mt-12 text-everglade font-light">
          {description}
        </div>
        <div className="mt-4 text-cinnabar">
          <LinkInternal
            target={link.uri}
            label={link.title || `Read more`}
            theme={`cinnabar`}
          />
        </div>
      </div>
    </div>
  );
};

export default Section;
