import React from "react";

import { BsArrowDown } from "react-icons/bs";
import Logo from "images/LogoShape";
import Image from "./Image";
import LinkInternal from "./LinkInternal";

const Hero = ({ heading, slides }) => {
  return (
    <div className="relative pt-20">
      <div className="absolute top-0 left-0 bottom-0 z-0 h-full w-full md:w-7/12 lg:w-[45%] xl:w-7/12 bg-everglade"></div>
      <div className="relative z-10 lg:container my-8 lg:mx-auto">
        <BsArrowDown className="absolute bottom-36 ml-4 h-8 w-8 text-cinnabar-500 z-10" />
        <Logo
          fill={`white`}
          className={`absolute w-1/2 md:w-1/3 right-[10%] md:right-[55%] transform rotate-180 top-32 md:top-auto md:bottom-[-50px] opacity-10`}
        />
        <h2 className="md:absolute max-w-sm md:max-w-[20rem] 2xl:max-w-lg mx-6 xl:mx-0 mb-8 lg:mb-0 lg:pt-16 lg:pr-4 text-white text-2xl xl:text-4xl uppercase font-bold tracking-wider leading-tight">
          {heading}
        </h2>

        <div>
          {slides &&
            slides.map((slide, i) => {
              return (
                <div
                  key={slide.project[i].id || i}
                  className="flex flex-col lg:flex-row z-20 relative"
                >
                  <div className="order-2 relative lg:order-1 mt-auto flex-1 py-8 pr-4 lg:w-2/6 lg:ml-[calc(-50vw+50%)] lg:pl-[calc(50vw-50%)] bg-cinnabar-500 text-white z-10">
                    <div className="max-w-sm mx-6 lg:max-w-auto xl:mx-0 lg:pr-4">
                      <h3 className="font-bold uppercase text-xl">
                        {slide.project[i].title}
                      </h3>
                      <p className="font-light text-sm">
                        {slide.project[i].singleSector.detail.location}
                      </p>
                      <div className="flex justify-between items-center">
                        <p className="text-sm">
                          {slide.project[i].singleSector.detail.projectType}
                        </p>
                        <LinkInternal
                          target={slide.project[i].uri}
                          label={`Read more`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="order-1 lg:order-2 lg:w-4/6">
                    <Image image={slide.image} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Hero;
