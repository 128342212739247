import React from "react";
import { graphql } from "gatsby";

import Layout from "components/commercial/Layout";
import Hero from "components/Hero";
import Sections from "components/commercial/Sections";

export const query = graphql`
  {
    wp {
      page(id: "/commercial/", idType: URI) {
        id
        title
        slug
        commercialHome {
          hero {
            heading
            slider {
              project {
                ... on WP_Sector {
                  id
                  title
                  uri
                  singleSector {
                    detail {
                      client
                      duration
                      fieldGroupName
                      location
                      projectType
                      scope
                      value
                    }
                  }
                }
              }
              image {
                ...MediaItem
              }
            }
          }
          sections {
            image {
              ...MediaItem
            }
            label
            heading
            description
            link {
              ... on WP_Page {
                id
                uri
              }
            }
          }
        }
      }
    }
  }
`;

const commercial = ({ data }) => {
  const { page } = data.wp;
  const {
    commercialHome: { hero, sections },
  } = page;
  return (
    <Layout title={page.title}>
      <main>
        <Hero heading={hero.heading} slides={hero.slider} />
        <Sections sections={sections} />
      </main>
    </Layout>
  );
};

export default commercial;
